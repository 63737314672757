import { graphql, useStaticQuery } from "gatsby";

const useSiteMetaData = () =>
  useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          footer
          fr
          en
          es
        }
      }
    }
  `);

export default useSiteMetaData;
