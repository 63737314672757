import React from "react";
import { Tooltip } from "react-tippy";
import { Link, withPrefix } from "gatsby";
import { navigate } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import headerStyles from "../Header/Header.module.scss";
import styles from "./LanguageSelect.module.scss";
import useLanguage from "./useLanguage";
import useSiteMetaData from "../../hooks/useSiteMetaData";

import "react-tippy/dist/tippy.css";

const changeLanguage = (lang) => () => {
  const prefix = `${withPrefix("/")}${lang}/`;
  const pathPrefixLength = prefix.length;
  const newPath = `/${lang}/${window.location.pathname.substring(
    pathPrefixLength
  )}`;
  localStorage.setItem("paylicanwebsitelanguage", lang);

  navigate(newPath);
};

const LanguagesList = ({ language }) => (
  <div className={styles.languageSelectorMain}>
    <div
      className={language === "en" ? styles.languageSelectorSelected : ""}
      onClick={changeLanguage("en")}
    >
      {language === "en" && (
        <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10 }} />
      )}
      English
    </div>
    <div
      className={language === "fr" ? styles.languageSelectorSelected : ""}
      onClick={changeLanguage("fr")}
    >
      {language === "fr" && (
        <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10 }} />
      )}
      Français
    </div>
    {/*<div className={language === 'es' ? styles.languageSelectorSelected : ''} onClick={changeLanguage('es')}>
        {language === 'es' && <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}} />}Español
    </div>*/}
  </div>
);

const LanguageSelect = ({ isMobile, languageLabel }) => {
  const data = useSiteMetaData();

  const language = useLanguage();

  let locationPathname = undefined;
  const pathPrefixLength = withPrefix("/").length;
  if (typeof window !== `undefined`) {
    locationPathname = window.location.pathname;
    // check if language in the URL is same as the one selected
    const urlLanguage = locationPathname.substring(
      pathPrefixLength,
      pathPrefixLength + 2
    );

    if (urlLanguage !== language) {
      changeLanguage(urlLanguage)();
    }
  }

  return (
    <>
      {!isMobile && (
        <Tooltip
          arrow
          interactive
          position="bottom"
          trigger="mouseenter"
          theme="light"
          tag="span"
          html={<LanguagesList data={data} language={language} />}
        >
          <Link
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
            to={locationPathname}
          >
            {data.site.siteMetadata[language]}
          </Link>
        </Tooltip>
      )}
      {isMobile && (
        <span className={styles.navItem}>
          {languageLabel}
          <ul className={styles.subMenu}>
            <li className={styles.subMenuItem} onClick={changeLanguage("en")}>
              {language === "en" && (
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10 }} />
              )}
              <a>English</a>
            </li>
            <li className={styles.subMenuItem} onClick={changeLanguage("fr")}>
              {language === "fr" && (
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10 }} />
              )}
              <a>Français</a>
            </li>
          </ul>
        </span>
      )}
    </>
  );
};

export default React.memo(LanguageSelect);
