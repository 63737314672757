const useLanguage = () => {
  let navigatorLanguage = "en";
  if (typeof navigator !== `undefined`) {
    navigatorLanguage = navigator.language || navigator.userLanguage;
  }

  let paylicanWebsiteLanguage = undefined;
  if (typeof localStorage !== `undefined`) {
    paylicanWebsiteLanguage = localStorage.getItem("paylicanwebsitelanguage");
  }

  const persistedLanguage = paylicanWebsiteLanguage
    ? paylicanWebsiteLanguage
    : navigatorLanguage.substring(0, 2);

  let language = "en";
  switch (persistedLanguage) {
    case "en":
    case "fr":
    case "es":
      language = persistedLanguage;
      break;
    default:
      language = "en";
  }

  return language;
};

export default useLanguage;
